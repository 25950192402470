.Dashboard  {
position: absolute;
width: calc(100% - 10px);
height: calc(100% - 80px);
top: 70px;

}

.Dashboard .message {
display: flex;
flex-direction: row;
justify-content: flex-end;
}



@media screen and (max-width: 1100px) {
    .Dashboard .content {
        height: calc(100% - 70px);
        width: 100%;
        display: flex;
        flex-direction: column;
    }  
    .Dashboard .content > * {
        margin-top: 20px;
        width: 100%;
        height: calc(80vh);
    }  
}

@media screen and (min-width: 1100px) {
  .Dashboard .content {
    height: calc(100% - 100px);
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
} 
.Dashboard .content > * {
    width: calc(100% / 3 - 40px );
    height: calc(40vh - 20px);
 } 
}
