.AssetDocuments {

}

.AssetDocuments .title {

    font-size: large;
    font-weight: bold;
}

.AssetDocuments .list {
    min-height: 100px;
    border-radius: 5px;
    width: 100%;
    height: fit-content;
}

.AssetDocuments .list .noFiles {
    width: inherit;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: gray;
}