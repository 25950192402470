

.AssetHistory .title {
    font-size: large;
    font-weight: bold;

}

.AssetHistory .list {
    min-height: 100px;
    width: 100%;
    border-radius: 3px;


}

.AssetHistory .noData {
    height: 100px;
    width: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: gray;

}

