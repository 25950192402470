.OtherTradesman  {
    padding: 10px;
    height: 300px;
    width: calc(100% - 20px);
    margin-top: 20px;

}

.OtherTradesman .title {
    font-weight: bold;
    font-size: large;
    text-transform: capitalize;

}
