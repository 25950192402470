.AssetHistoryPage {
    width: calc(100% - 20px);
    max-width: 1000px;
    position: relative;
    height: calc(100vh - 100px);
    margin-top: 70px;
    padding: 10px;
}

.AssetHistoryPage .details {
    position: absolute;
    right: 10px;
    top: 10px;
    text-align: right;
}

.AssetHistoryPage .title {
    font-size: xx-large;
    font-weight: bold;
    color: rgb(18, 100, 100);

}

.AssetHistoryPage .subtitle {
color: gray;
}

.AssetHistoryPage .content {
    margin-top: 80px;
}

