.JobPage {
        width: calc(100% - 20px);
    max-width: 1000px;
    height: calc(100vh - 80px);
    margin-top: 70px;
    

}


.JobPage .quickInfo {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
}

.JobPage .quickInfo .info {
   text-align: right;
 }

.JobPage .quickInfo .title {
    font-weight: bold;
    font-size: xx-large;
    color: rgb(18, 100, 100);
}

.JobPage .quickInfo .subtitle {
    color: gray;

}


.JobPage .content {
    width: 100%;
    height: calc(100% - 100px);
    position: relative;
    overflow: scroll;
}

.JobPage .content .fab {
    position: fixed;
    bottom: 10px;
    right: 10px;

}


.JobPage .content .actions {
   width: 50px;
   height: 330px;
   position: fixed;
transition: all 1s linear;
display: flex; 
flex-direction: column;
justify-content: center;
align-items: center;
right: 15px;

}

.JobPage .content .actionsHidden {
    bottom: -300px;
    height: 10px;
    visibility: hidden;
}


.JobPage .content .actionsShown {
    bottom: 80px;
}


.JobPage .content > *:nth-child(-n+4) {
    padding: 10px;
    margin-bottom: 20px;
}

.JobPage .content > *:nth-child(-n+4) .title {
    font-weight: bold;
    font-size: large;
}

