
.AssetPhotos .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    height: calc(100% - 30px);

}

.AssetPhotos .title {
    font-size: large;
    font-weight: bold;
}


.AssetPhotos .imgleft {
    position: absolute;
    top: 0px;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    left: 0px;
    color: rgba(18, 100, 100, 0.4);

}

.AssetPhotos .imgright {
    position: absolute;
    top: 0px;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    right: 0px;
    color: rgba(18, 100, 100, 0.4);

}

.AssetPhotos .imgleft:hover {
    color: rgb(18, 100, 100);
    border-radius: 3px;
    cursor: pointer;

    background-image : linear-gradient(to right, 
    rgba(255,255,255, 1), 
    rgba(255,255,255, 0) 90%);





}

.AssetPhotos .imgright:hover {
    background-image : linear-gradient(to left, 
    rgba(255,255,255, 1), 
    rgba(255,255,255, 0) 90%);



    color: rgb(18, 100, 100);
    border-radius: 3px;
    cursor: pointer;

}

.AssetPhotos .imgleft > * {
    left: 20px;
    position: absolute;
}
.AssetPhotos .imgright > * {
    right: 20px;
    position: absolute;
    
}


.AssetPhotos .img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}
