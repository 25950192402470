.TrackingDialogContent_content {
    padding: 10px;
}

.TrackingDialogContent_title {

}


.RequestTrackingPage_parent {
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.RequestTrackingPage_child {
    width: 98%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.RequestTrackingPage_card {
    min-height: 400px;
    padding: 10px;

}

.RequestTrackingPage_card_progress {
    color: rgb(18, 100, 100);
}

.RequestTrackingPage_card_button_bar {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
}
.RequestTrackingPage_img_card_div {
    padding-top: 10px;
    padding-bottom: 10px;


}

/* small screens  */
@media screen and (max-width: 1000px) {
    .RequestTrackingPage_img_card {
        width: calc(100% - 20px);
        height: 30vh;
    }  
}
@media screen and (min-width: 1000px) {
    .RequestTrackingPage_img_card {
        width: 80%;
        max-width: 600px;
        height: 30vh;
    }  
}



.RequestTrackingPage_textField {
    padding-top: 5px;
    padding-bottom: 5px;
}


.RequestTrackingPage_dialog {
    max-height: 80vh;
    padding: 10px;
}

.RequestTrackingPage_title {
    border-bottom: solid;
    border-width: 1px;
    border-width: 100%;
    border-color: gray;
    padding-bottom: 5px;
    font-weight: 600;

}

.RequestTrackingPage_button_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: rgb(18, 100, 100)
}

.RequestTrackingPage_sub_card {
    width: calc(100% - 20px);
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    height: 300px;

}

.RequestTrackingPage_subtitle {
    font-size: large;
    font-weight: 500;
    border-bottom: solid;
    border-width: 1px;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 5px;

}

.RequestTrackingPage_message {
    width: 100%;
    height: 260px;
    overflow: scroll;
    scroll-behavior: smooth;

}

.Timeline_list {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 10px;

}


.Timeline_list::after {
    background-color: rgb(18, 100, 100);
    content: '';
    position: absolute;
    width: 4px;
    height: calc(100% - 110px);
    margin-top: 50px;

}

.TimelineItem_item {
    display: flex;
    justify-content: flex-start;
    margin-left: 30px;
    position: relative;
    width: calc(100% - 23px) ;
    padding-bottom: 10px;
}


.TimelineItem_item_content {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;
    position: relative;
    width: 100%;
    text-align: right;
    height: 80px;
}
.TimelineItem_item_content::after {
    content: ' ';
    width: 15px;
    height: 15px;
    position: absolute;
    transform: rotate(45deg);
    left: -7.5px;
    top: calc(50% - 7.5px);
    background-color: white;
    box-shadow:  -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.TimelineItem_item_content .circle {
    color: rgb(18, 100, 100);
     z-index: 100; 
     border: solid;
     background-color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px; 
    position: absolute;
    top: calc(50% - 13px);
    left: -41px;
    font-size: large;
    font-weight: 500;
}


.TimelineItem_time {
    font-size: small;
    color: gray;
}

.TimelineItem_title {
    padding-top: 5px;
    font-size: large;
    font-weight: 500;
}




/* pending requests */

.TimelineItem_item-incomplete {
    display: flex;
    justify-content: flex-start;
    margin-left: 30px;
    position: relative;
    width: calc(100% - 23px) ;
    padding-bottom: 10px;
}


.TimelineItem_item_content-incomplete {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: rgb(238, 238, 238);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;
    position: relative;
    width: 100%;
    text-align: right;
    height: 80px;
}

.TimelineItem_item_content-incomplete .circle {
    color: gray;
     z-index: 100; 
     border: solid;
     background-color: rgb(238, 238, 238);
    border-radius: 50%;
    width: 20px;
    height: 20px; 
    position: absolute;
    top: calc(50% - 13px);
    left: -41px;
    font-size: large;
    font-weight: 500;
}

.TimelineItem_item_content-incomplete::after {
    content: ' ';
    width: 15px;
    height: 15px;
    position: absolute;
    transform: rotate(45deg);
    left: -7.5px;
    top: calc(50% - 7.5px);
    background-color: rgb(238, 238, 238);
    box-shadow:  -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.TimelineItem_title-incomplete {
    padding-top: 5px;
    font-size: large;
    font-weight: 500;
}
