.JobAttachments {
    min-height: 100px;
    position: relative;
   
}

.JobAttachments .list {
    height: calc(100% - 30px);
    overflow: scroll;

}


.JobAttachments .none {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.JobAttachments .list .ListFab {
    position: absolute;
    bottom: 10px;
    right: 10px;

}
