.PublicProfileEdit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}


.PublicProfileEdit .card {
    width: 800px;
    max-width: calc(100% - 20px);
    padding: 10px;
    position: relative;
}

.PublicProfileEdit .card .saveButton {
position: absolute;
right: 10px;
top: 10px;
}


.PublicProfileEdit .card  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 5000;

}

.PublicProfileEdit .card .title {
    margin-bottom: 10px;

}

.PublicProfileEdit .card .topsection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.PublicProfileEdit .card .topsection .left {
    width: calc(50% - 20px);
    width: calc(100% - 220px);

}



.PublicProfileEdit .card .topsection .left .fullwidth {
    padding-top: 5px;
    padding-bottom: 5px;

}

.PublicProfileEdit .card .topsection .left .halves {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.PublicProfileEdit .card .topsection .left .half {
    padding-top: 5px;
    padding-bottom: 5px;
    width: calc(50% - 5px);

}




.PublicProfileEdit .card .topsection .right {
    width: calc(206px);
}

.PublicProfileEdit .card .image {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;


}

.PublicProfileEdit .card .actualImg {
    border-radius: 100px;
    height: 200px;
    width: 200px;
    object-fit:cover;
}

.PublicProfileEdit .card .image .fab {
   position: absolute;
   bottom: 0px;
   right: 0px;
}


.subtitle {
    margin-top: 20px;
}

.PublicProfileEdit .card  .chips {
    height: 100px;
    border: solid 1px lightgray;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
    overflow: scroll;

}

.PublicProfileEdit .card .chip {
    margin-right: 5px;
    margin-bottom: 5px;

}

.PublicProfileEdit .card .htmlInput {
    margin-top: 0px;
}

