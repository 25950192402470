.InProgressJobs {
    padding: 10px;
}

.InProgressJobs .title {
    font-size: large;
    font-weight: bold;
}

.InProgressJobs .list {
    width: 100%;
    height: calc(100% - 30px);
    overflow: scroll;
    border-radius: 5px;
    position: relative;

}



 .InProgressJobs .list .searchIcon {
    position: absolute;
    bottom: 5px;
    right: 5px;

 }


 .InProgressJobs .list .search {
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 500;

 }

.InProgressJobs .list .search .textfield {
    width: calc(100% - 50px);

}
