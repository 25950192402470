.PropertyAccess  {
    padding: 10px;
    width: calc(100% - 20px);
}

.PropertyAccess .title {
    font-size: large; 
    font-weight: bold;  
}


.PropertyAccess .imgSection {
    height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.PropertyAccess .img {
    object-fit: contain;
    max-height: 300px;
    max-width: calc(100% - 20px);
}

.PropertyAccess .message {
    min-height: 30px;
    max-height: 150px;
    overflow: scroll;

}
