.DialogContent {
    padding: 10px;

}

.DialogContent .title {
    font-size: large;
    font-weight: medium;
    text-align: center;

}

.DialogContent .subtitle {
    text-align: center;
    margin-top: 10px;


}

.DialogContent .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;

}
