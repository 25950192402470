.message {
    border-radius: 5px;
    position: relative;
    height: fit-content;
    padding: 10px;
    padding-bottom: 30px;
}

.message .iconButton {
    position: absolute;
    bottom: 5px;
    right: 5px;
    
}
