.ClientDetails {
    padding: 10px;
    
}

.loading {
    width:  calc(100% - 20px);
    height: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 20;
}

.ClientDetails .hr {
    margin-top: 32px;

}

.ClientDetails .avatar {
    position: absolute;
    top: 10px;
    border: 5px solid white;
    margin-left: calc(50% - 45px);



}
.ClientDetails .ClientName {
    text-align: center;
    font-weight: bold;
    font-size: x-large;
    margin-top: 35px;
}


.ClientDetails .saveDiv {
    margin-bottom: 50px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.ClientDetails .footer {
display: flex;
flex-direction: row;
justify-content: space-between;
position: absolute;
bottom: 10px;
width: calc(100% - 20px);
align-items: center;
margin-top: 40px;
}

.ClientDetails .address {
    color: gray;

}

.ClientDetails .ContactButtons {
    width: 75px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

