.SceduleJobDialogContent {
padding:10px;
position: relative;

}

.SceduleJobDialogContent .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 50;

}

.SceduleJobDialogContent .title {
    font-size: large;
    font-weight: medium;
    border-bottom: solid 1px lightgray;
    margin-bottom: 20px;


}

.SceduleJobDialogContent .fields {
    margin-bottom: 20px;

}

.SceduleJobDialogContent .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

}
