.AssetEventDetails {
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;

}

.AssetEventDetails .title {
    font-weight: bold;
    font-size: large;
    color: black;
}

.AssetEventDetails .content {
    margin-top: 0px;

}

.AssetEventDetails .section {
    border: 1px lightgray solid;
    border-radius: 5px;
    position: relative;
    min-height: 20px;
    margin-top: 20px;
    padding: 10px;
}

.AssetEventDetails .section .title {
    position: absolute;
    background-color: white;
    top: -10px;
    font-size: small;
    color: gray;
    padding-left: 5px;
    padding-right: 5px;
    left: 10px;

}

.AssetEventDetails .halves {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.AssetEventDetails .halves .section {
    border: 1px lightgray solid;
    border-radius: 5px;
    position: relative;
    min-height: 20px;
    margin-top: 20px;
    width: calc(50% - 30px);

}
