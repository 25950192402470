.JobPhotos .parent {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

}

.JobPhotos .parent .image {
    max-width: 90%;
    max-height: 100%;
    object-fit: contain;
}


.JobPhotos .imgleft {
    position: absolute;
    top: 0px;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    left: 0px;
    color: rgba(18, 100, 100, 0.4);

}

.JobPhotos .imgright {
    position: absolute;
    top: 0px;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    right: 0px;
    color: rgba(18, 100, 100, 0.4);

}

.JobPhotos .imgleft:hover {
    color: rgb(18, 100, 100);
    border-radius: 3px;
    cursor: pointer;

    background-image : linear-gradient(to right, 
    rgba(255,255,255, 1), 
    rgba(255,255,255, 0) 90%);





}

.JobPhotos .imgright:hover {
    background-image : linear-gradient(to left, 
    rgba(255,255,255, 1), 
    rgba(255,255,255, 0) 90%);



    color: rgb(18, 100, 100);
    border-radius: 3px;
    cursor: pointer;

}

.JobPhotos .imgleft > * {
    left: 20px;
    position: absolute;
}
.JobPhotos .imgright > * {
    right: 20px;
    position: absolute;
    
}

