.ClientProperties {
    width: calc(100% - 20px);
    height: calc(100%);
    padding: 10px;
    position: relative;
}


.ClientProperties .title {
    font-weight: bold;
    font-size: large;
}


.ClientProperties .list {
    width: 100%;
    height: calc(100% - 30px);
    overflow: scroll;
    position: relative;
    border-radius: 5px;

}

.ClientProperties .IconButton {
    position: absolute; 
    bottom: 5px;
    right: 5px;

}


.ClientProperties .search {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 500;



}

.ClientProperties .search .searchBar {
    width: calc(80%);

}

