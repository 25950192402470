.ProductDetails {
  

}

.ProductDetails .title {
    font-size: large;
    font-weight: bold;
    color: black;
}

.ProductDetails .item {
    display: flex;
    flex: row;
    justify-content: flex-start;
    align-items: center;

}

.ProductDetails .items .title {
    color: rgb(18, 100, 100);
    size: medium;
    margin-right: 10px;

}

.ProductDetails .value {
    margin-right: 20px;

}
