.AssetEventAttachments {
    padding: 10px;

}

.AssetEventAttachments .title {
    font-weight: bold;
    font-size: large;
    color: black;
}


.AssetEventAttachments .list {
    border-radius: 5px;
    min-height: 100px;
}

.AssetEventAttachments .list .noFiles {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: gray;
    
}