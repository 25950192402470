.Assets  {
    width: calc(100% - 20px);
    padding: 10px;
    height: 150px;
    margin-top: 20px;
}

.Assets .title {
    font-size: large;
    font-weight: bold;
}

.Assets .list {
    width: calc(100%);
    height: calc(100% - 30px);
    border-radius: 5px;
    overflow: scroll;
}
