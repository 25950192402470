.AccountDetails {
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.AccountDetails .resetPassword {
    width: 500px;
    height: 295px;
    max-width: calc(100% - 20px);
    max-height: calc(100vh - 200px);
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    position: relative;

}


.AccountDetails .resetPassword .loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 50;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);

}


.AccountDetails .resetPassword .title {
    font-size: large;
    font-weight: medium;
}

.AccountDetails .resetPassword .field {
    padding-top: 10px;
    padding-bottom: 10px;

}

.AccountDetails .resetPassword .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

}
