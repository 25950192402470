.SpacesPage {
    height: calc(100vh - 100px);
    width: calc(100vw - 20px);
    max-width: 1300px;
    margin-top:50px;
    padding: 10px;
}

.SpacesPage .quickDetails {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.SpacesPage .details {
    text-align: right;
}

.SpacesPage .details .title {
    font-size: x-large;
    font-weight: bold;
}

.SpacesPage .subtitle {
    color: gray;
}



.SpacesPage .secondRow {
    height: fit-content;
    margin-top: 20px;
}



.SpacesPage .firstRow > * {
padding: 10px;
}

.SpacesPage .secondRow > * {
 padding: 10px;   
}

.SpacesPage .firstRow > * > .title {
    font-size: large;
    font-weight: bold;
    }
    
    .SpacesPage .secondRow > * > .title {
     font-size: large;
     font-weight: bold;   
    }



@media screen and (min-width: 800px) {
    .SpacesPage .firstRow {
        height: calc(40vh);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
    }
    
    .SpacesPage .firstRow > * {
        height: calc(100% - 20px);
        width: calc(50% - 30px);
        padding: 10px;
    }
    
}

@media screen and (max-width: 800px) {
    .SpacesPage .firstRow {
    }
    
    .SpacesPage .firstRow > * {
        height: calc(70vh);
        width: calc(100% - 20px);
        padding: 10px;
        height: calc(40vh);
        margin-top: 20px;
    }
    
}

.SpacesPage .secondRow > * {
    height: fit-content;
    width: calc(100% - 20px);
}