.AssetPage {
    width: calc(100% - 20px);
    max-width: 1300px;
    height: calc(100vh - 100px);
    margin-top: 70px;
    position: relative;
    padding: 10px;

}

.AssetPage .details {
    position: absolute;
    right: 10px;
    top: 10px;

}

.AssetPage .details .DetailsTitle {
    font-size: xx-large;
    font-weight: bold;
    color: rgb(18, 100, 100);
    text-transform: capitalize;
}

.AssetPage .DetailsSubtitle {
    text-align: right;
    text-transform: capitalize;
    color: gray;

}



@media screen and (min-width: 1000px) { /* big boy screen size */

    .AssetPage {
        display: flex;
    flex-direction: row;
    justify-content: space-between;
    }

    .AssetPage .left {
    width: calc(50% - 20px);
    height: 100%;

}

.AssetPage .right {
    width: calc(50% - 20px);
    height: calc(100%  - 100px);
    margin-top: 100px;

}



.AssetPage .left > * {
    margin-bottom: 20px;
    padding: 10px;
    width: calc(100% - 20px);
    height: fit-content;
}

.AssetPage .right > * {
    margin-bottom: 20px;
    padding: 10px;
    width: calc(100% - 20px);
    height: fit-content;  
}
}

@media screen and (max-width: 1000px) {
    .AssetPage {
        display: flex;
    flex-direction: column;
    }
    
    .AssetPage .left {
    width: calc(100%);
    height: fit-content;
    min-height: 50px;
    margin-top: 100px;

}

.AssetPage .right {
    width: calc(100%);
    height: calc(100%  - 100px);
    height: fit-content;
    min-height: 50px;
    margin-top: 40px;

}

.AssetPage .left > * {
    margin-bottom: 20px;
    padding: 10px;
    width: calc(100% - 20px);
    height: fit-content;
    min-height: 200px;
}

.AssetPage .right > * {
    margin-bottom: 20px;
    padding: 10px;
    width: calc(100% - 20px);
    height: fit-content;  
    min-height: 200px;
}


    
}
