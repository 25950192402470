.Settings {
    width: calc(100vw - 20px);
    height: calc(100vh - 90px);
    margin-top: 50px;
    position: relative;
}

.Settings .sidebar {
    position: absolute;
    width: 230px;
    border-radius: 10px;
    background-color: rgb(18, 100, 100);
    height: calc(100vh - 150px);
    padding: 10px;
    z-index: 500;
}

.Settings .closed {
    left: -260px;
    top: 50px;
    transition: 0.5s linear;

}

.Settings .open {
    left: 0px;
    top: 50px;
    transition: 0.5s linear;


}

.Settings .toolbar {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.Settings .toolbar .title {
    font-size: large;
    font-weight: bold;
    margin-right: 10px;

}

.Settings .page {
    height: calc(100vh - 140px);
    margin-top: 10px;
}
