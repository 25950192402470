.InvoicedJobs {
   width: calc(100% - 20px);
   height: calc(100%);
   padding: 10px;
   position: relative;
}
 .InvoicedJobs .title {
    font-size: large;
    font-weight: bold;
 }

 .InvoicedJobs .list {
    width: calc(100%);
    height: calc(100% - 30px);
    border-radius: 5px;
    position: relative;
    overflow: scroll;
 }


 .InvoicedJobs .list .searchIcon {
    position: absolute;
    bottom: 5px;
    right: 5px;

 }


 .InvoicedJobs .list .search {
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 500;

 }

.InvoicedJobs .list .search .textfield {
    width: calc(100% - 50px);

}
