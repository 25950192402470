.PropertyFloorPlan {
    width: calc(100% - 20px);
    padding: 10px;
}

.PropertyFloorPlan .title {
    font-size: large;
    font-weight: bold;
}

.PropertyFloorPlan .floorPlan {
    height: calc(320px);
    width: calc(100% - 20px);
    display: flex;
    flex: row;
    justify-content: center;
    object-fit: contain;

}
