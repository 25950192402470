.PropertiesPage {
  width: 100%;
  height: calc(100vh - 80px);
  position: absolute;
  top: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.PropertiesPage .item {
  color: gray;
  position: absolute;
  right: 5px;
  text-align: end;
}

.PropertiesPage .item .line:nth-child(1) {
  color: black;
  font-weight: bold;
}

.PropertiesPage .content {
  width: calc(100% - 20px);
  max-width: 1300px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 1000px) {
  .PropertiesPage .content {
    width: calc(100% - 20px);
    max-width: 1300px;
    height: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .PropertiesPage .content .left {
    width: 100%;
    height: fit-content;
    margin-top: 100px;
  }

  .PropertiesPage .content .right {
    width: 100%;
    height: fit-content;
  }

  .PropertiesPage .content > *:first-child > * {
    margin-top: 0px;
  } 
  
  .PropertiesPage .content > * > * {
    margin-top: 20px;
  }  
}

@media screen and (min-width: 1000px) {
  .PropertiesPage .content {
    width: calc(100% - 20px);
    max-width: 1300px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .PropertiesPage .content .left {
    width: calc(50% - 20px);
  }

  .PropertiesPage .content .right {
    width: calc(50% - 20px);
    margin-top: 100px;
  }
}
