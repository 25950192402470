.StripeDashboard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;


}


.StripeDashboard .loading {
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 500;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.StripeDashboard .card {
    width: 100%;
    max-width: 500px;
    padding: 10px;

}

.StripeDashboard .title {
    font-size: xx-large;
    font-weight: bold;
    color: rgb(18, 100, 100);
    text-align: center;

}

.StripeDashboard .helper {
    text-align: center;
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.StripeDashboard .buttonSection {
    display: flex;
    flex-direction: row;
    justify-content: center;

}
