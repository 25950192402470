.InvoiceDialog {
    padding: 10px;
    position: relative;
}


.InvoiceDialog .paidBadge {
    position: absolute;
    height: 100px;
    top: 10px;
    right: 0px;


}


.InvoiceDialog  .circularProgress {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    height: 100%;
    z-index: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.InvoiceDialog .title {
    font-size: large;
    font-weight: bold;

}


.InvoiceDialog .payoutHelper {
    text-align: end;
    font-size: x-small;
    margin-bottom: 10px;
}


.InvoiceDialog .fields {
    margin-top: 20px;
}

.InvoiceDialog  .fields .fullwidth {
    margin-bottom: 10px;
}

.InvoiceDialog  .fields .button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}