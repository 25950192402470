.Spaces  {
    width: calc(100% - 20px);
    padding: 10px;
    height: 200px;
    margin-top: 20px;

}

.Spaces .title {
    font-size: large;
    font-weight: bold;
}

.Spaces .list {
    width: calc(100% - 20px);
    height: calc(100% - 30px);
    overflow: scroll;
    border-radius: 5px;

}
