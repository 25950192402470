.DialogContent {
    padding: 10px;

}

.DialogContent .title {
    font-size: large;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;

}

.DialogContent .subtitle {
    text-align: center;
    margin-top: 10px;


}

.DialogContent .actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;

}
